/* import tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

/*
Custom CSS
1. Header
2. Avatar
3. Dropdown
4. Tooltip
5. Checkbox
6. Dialog
7. Close button
8. Table
9. Menu item
10. Tab
11. Tag
12. Month Calendar
13. Chart
14. Menu Bar
15. Skeleton
16. Hide scrollbar
17. Select
18. Time Line
*/

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  tab-size: 4;
}
body {
  line-height: inherit;
  margin: 0;
}

*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}
:after,
:before {
  --tw-content: "";
}

*,
::backdrop,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-color: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.main-d:nth-last-child(1) {
  border-bottom: none;
}

/* ================================================================================================================ */
/* 1. Header */
/* ================================================================================================================ */

.header-wrapper {
  justify-content: space-between;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}
.header-action,
.header-wrapper {
  align-items: center;
  display: flex;
}

/* ================================================================================================================ */
/*  2. Avatar */
/* ================================================================================================================ */
.avatar {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  display: inline-block;
  position: relative;
}
.dark .avatar {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.avatar-sm {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
}
.avatar-md {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg,
.avatar-md {
  line-height: 2.5rem;
}
.avatar-lg {
  height: 3rem;
  width: 3rem;
}
.avatar-rounded {
  border-radius: 0.375rem;
}
.avatar-square {
  border-radius: 0;
}
.avatar-circle {
  border-radius: 9999px;
}
.avatar-img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.avatar-string {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  transform-origin: center;
}
.avatar-inner-sm {
  height: 2rem;
}
.avatar-inner-md {
  height: 2.5rem;
}
.avatar-inner-lg {
  height: 3rem;
}
.avatar-icon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.avatar-icon-sm {
  font-size: 1.125rem;
}
.avatar-icon-md,
.avatar-icon-sm {
  line-height: 1.75rem;
}
.avatar-icon-md {
  font-size: 1.25rem;
}
.avatar-icon-lg {
  font-size: 1.5rem;
  line-height: 2rem;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group-chained > .avatar,
.avatar-group-chained > .tooltip-wrapper {
  margin-left: -0.5rem;
}
[dir="rtl"] .avatar-group-chained > .avatar,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper {
  margin-right: -0.5rem;
}
.avatar-group-chained > .avatar:first-child,
.avatar-group-chained > .tooltip-wrapper:first-child {
  margin-left: 0;
}
[dir="rtl"] .avatar-group-chained > .avatar:first-child,
[dir="rtl"] .avatar-group-chained > .tooltip-wrapper:first-child {
  margin-right: 0;
}

/* ================================================================================================================ */
/* 3. Dropdown */
/* ================================================================================================================ */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px 0 0 #0000, 0 4px 6px -4px 0 0 #0000;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
  --tw-ring-opacity: 0.05;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.375rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  min-width: 160px;
  padding: 0.375rem;
  position: absolute;
  z-index: 30;
}
.dropdown-menu:focus {
  outline: 2px solid #0000;
  outline-offset: 2px;
}
.dark .dropdown-menu {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px 0 0 #0000;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dropdown-menu.top-start {
  bottom: 100%;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.top-start {
  right: 0;
}
.dropdown-menu.top-center {
  left: 50%;
}
.dropdown-menu.top-center,
.dropdown-menu.top-end {
  bottom: 100%;
  transform-origin: bottom;
}
[dir="ltr"] .dropdown-menu.top-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.top-end {
  left: 0;
}
.dropdown-menu.bottom-start {
  top: 100%;
  transform-origin: top;
  right: 0;
}
[dir="ltr"] .dropdown-menu.bottom-start {
  left: 0;
}
[dir="rtl"] .dropdown-menu.bottom-start {
  right: 0;
}
.dropdown-menu.bottom-center {
  left: 50%;
}
.dropdown-menu.bottom-center,
.dropdown-menu.bottom-end {
  top: 100%;
  transform-origin: top;
  right: 0;
}
[dir="ltr"] .dropdown-menu.bottom-end {
  right: 0;
}
[dir="rtl"] .dropdown-menu.bottom-end {
  left: 0;
}
.dropdown-menu.middle-start-top {
  left: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-menu.middle-start-bottom {
  bottom: 0;
  left: 100%;
  transform-origin: bottom;
}
.dropdown-menu.middle-end-top {
  right: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-menu.middle-end-bottom {
  bottom: 0;
  right: 100%;
  transform-origin: bottom;
}
.dropdown-toggle-default {
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem;
}
.dropdown-toggle-default.dropdown-toggle-disabled {
  opacity: 0.6;
}
.dropdown-submenu {
  left: 100%;
  top: 0;
  transform-origin: top;
}
.dropdown-submenu-item {
  justify-content: space-between;
}
.dropdown-toggle-disabled {
  cursor: not-allowed;
}

/* ================================================================================================================ */
/* 4. Tooltip */
/* ================================================================================================================ */
.tooltip {
  --tw-text-opacity: 1;
  border-radius: 0.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 250px;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 50;
}
.tooltip-wrapper {
  display: inline-flex;
}

/* ================================================================================================================ */
/* 5. Checkbox  */
/* ================================================================================================================ */
.checkbox {
  padding: 0;
}
.checkbox {
  --tw-border-opacity: 1;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 0 0 #0000;
  appearance: none;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 1.25rem;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: 1.25rem;
  --theme-color: #1c274c;
}
.dark .checkbox {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.checkbox:checked {
  background-color: var(--theme-color);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  border-color: #0000;
}
.checkbox.disabled {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(229 231 235 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.checkbox.disabled,
.dark .checkbox.disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.dark .checkbox.disabled {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.checkbox-label {
  cursor: pointer;
  display: inline-flex;
  margin-right: 0.75rem;
}
.checkbox-label.disabled {
  cursor: not-allowed;
}

/* ================================================================================================================ */
/* 6. Dialog  */
/* ================================================================================================================ */

.dialog {
  margin-left: auto;
  margin-right: auto;
  outline: none;
}
@media (min-width: 640px) {
  .dialog {
    max-width: 36rem;
  }
}
@media (min-width: 768px) {
  .dialog {
    max-width: 42rem;
  }
}
@media (min-width: 1024px) {
  .dialog {
    max-width: 56rem;
  }
}
@media (min-width: 1280px) {
  .dialog {
    max-width: 72rem;
  }
}
.dialog-content {
  --tw-bg-opacity: 1;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px 0 0 #0000, 0 8px 10px -6px 0 0 #0000;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 100%;
  padding: 1.5rem;
  position: relative;
}
.dark .dialog-content {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
@media (min-width: 640px) {
  .dialog-content {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
}
.dialog-overlay {
  --tw-bg-opacity: 0.6;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.15s ease-in-out;
  z-index: 30;
}
.dark .dialog-overlay {
  --tw-bg-opacity: 0.8;
}
.dialog-overlay-after-open {
  opacity: 1;
}
.dialog-overlay-before-close {
  opacity: 0;
}

/* ================================================================================================================ */
/* 7. Close button  */
/* ================================================================================================================ */

.close-btn {
  outline: 2px solid #0000;
  outline-offset: 2px;
}
.close-btn-default {
  border-radius: 0.25rem;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.25rem;
}
.close-btn-default,
.close-btn-default:hover {
  --tw-text-opacity: 1;
}
.close-btn-default:hover {
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

/* ================================================================================================================ */
/* 8. Table  */
/* ================================================================================================================ */

.table-default {
  min-width: 100%;
  table-layout: auto;
}

.table-default thead {
  background-color: #1c274c;
}
.table-default thead > tr > th {
  font-size: 16px;
  font-weight: 600;
  line-height: 1rem;
  padding: 1rem 1.5rem;
  text-align: left;
  color: white;
}

.table-default thead > tr > th:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.table-default thead > tr > th:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.table-default tbody > tr {
  background-color: #f4f7fe;
  /* background-color: #fafbff; */
}

.table-default tbody > tr > td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.table-default tbody > tr > td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.table-default tbody > tr > td {
  padding: 0.7rem 1.5rem;
  font-size: 14px;
}

/* ================================================================================================================ */
/* 9. Menu Item  */
/* ================================================================================================================ */

.menu-item {
  align-items: center;
  border-radius: 0.375rem;
  column-gap: 0.5rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  white-space: nowrap;
  width: 100%;
}

.menu-item-divider {
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.menu-item-divider {
  --tw-border-opacity: 1;
}

/* ================================================================================================================ */
/* 10. Tabs  */
/* ================================================================================================================ */

.tab-list {
  align-items: center;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.tab-list-underline {
  border-bottom-width: 1px;
}

.tab-nav {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tab-nav.tab-nav-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.tab-nav-underline {
  padding: 0.75rem 1.25rem;
}
.tab-nav-pill {
  border-radius: 0.375rem;
  margin-right: 0.25rem;
  padding: 0.5rem 1.25rem;
}

.tab-nav-icon {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-right: 0.375rem;
}

/* ================================================================================================================ */
/* 11. Tag  */
/* ================================================================================================================ */

.tag {
  align-items: center;
  display: inline-flex;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.25rem 0.3rem 0.25rem 0;
  /* padding: 0.25rem 0.625rem; */
  white-space: nowrap;
}
.tag {
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
.tag-affix {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  border-radius: 9999px;
  height: 0.5rem;
  width: 0.5rem;
}
.tag-prefix {
  margin-right: 0.375rem;
}
.tag-suffix,
.tag-prefix {
  margin-left: 0.375rem;
}

/* ================================================================================================================ */
/* 12. Month Calendar  */
/* ================================================================================================================ */

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  font-size: 15px !important;
  padding: 10px;
  color: #1c274c !important;
  font-family: "Plus Jakarta Sans", "Inter", "ui-sans-serif", "system-ui",
    "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto",
    '"Helvetica Neue"', "Arial", '"Noto Sans"', "sans-serif",
    '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"',
    '"Noto Color Emoji"' !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #fff !important;
  border: 1px solid lightgray !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #1c274c !important;
  font-family: "Plus Jakarta Sans", "Inter", "ui-sans-serif", "system-ui",
    "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto",
    '"Helvetica Neue"', "Arial", '"Noto Sans"', "sans-serif",
    '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"',
    '"Noto Color Emoji"' !important;
  font-size: 20px !important;
}

.react-datepicker__navigation {
  top: 6px !important;
}

.react-datepicker__header {
  border-bottom: 1px solid #1c274c !important;
}

.react-datepicker-popper .react-datepicker__triangle {
  stroke: #1c274c !important;
}

.react-datepicker {
  border: 1px solid #1c274c !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #1c274c !important;
  border-width: 2px 2px 0 0 !important;
}

/* ================================================================================================================ */
/* 13. Chart  */
/* ================================================================================================================ */

.apexcharts-legend.apx-legend-position-right {
  bottom: 0px !important;
  /* top: auto !important; */
  right: 0px !important;
}

.apexcharts-legend-marker {
  width: 24px !important;
  height: 24px !important;
}

.apexcharts-legend-text {
  color: #404040 !important;
  font-size: 16px !important;
  font-family: "Plus Jakarta Sans" !important;
}

/* .apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  flex-direction: row !important;
  gap: 20px !important;
} */

/* @media (max-width: 1535px) {
  .apexcharts-legend.apx-legend-position-right {
    right: 100px !important;
  }
} */

/* @media (max-width: 1280px) {
  .apexcharts-legend.apx-legend-position-right {
    right: 192px !important;
    bottom: 300px !important;
  }
  .apexcharts-legend.apx-legend-position-right,
  .apexcharts-legend.apx-legend-position-left {
    flex-direction: row !important;
    gap: 20px !important;
  }
} */

/* ================================================================================================================ */
/* 14. Menu Bar  */
/* ================================================================================================================ */

@keyframes slide-in {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@layer components {
  .line-animation {
    position: relative;
  }

  .line-animation::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: currentColor;
    transition: width 0.3s ease;
  }

  .line-animation:hover::after {
    width: 100%;
  }
}

.menuDrawer {
  width: 40% !important;
}

.stateDrawer {
  width: 350px !important;
}

.moduleDrawer {
  width: 420px !important;
}

@media (max-width: 720px) {
  .menuDrawer {
    width: 50% !important;
  }
}
@media (max-width: 575px) {
  .menuDrawer {
    width: 70% !important;
  }
}
@media (max-width: 410px) {
  .menuDrawer {
    width: 100% !important;
  }
}
@media (max-width: 421px) {
  .moduleDrawer {
    width: 100% !important;
  }
}

/* ================================================================================================================ */
/* 15. Skeleton */
/* ================================================================================================================ */

.skeleton {
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  display: flex;
}
.dark .skeleton,
.skeleton {
  --tw-bg-opacity: 1;
}
.dark .skeleton {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.skeleton-circle {
  border-radius: 9999px;
  height: 2.5rem;
  width: 2.5rem;
}
.skeleton-block {
  border-radius: 0.25rem;
  height: 0.625rem;
  width: 100%;
}

/* ================================================================================================================ */
/* 16. Hide scrollbar */
/* ================================================================================================================ */

::-webkit-scrollbar {
  display: none;
}

/* ================================================================================================================ */
/* 17. Select */
/* ================================================================================================================ */

.select .select__control--is-disabled {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  color: rgb(156 163 175 / var(--tw-text-opacity));
  cursor: not-allowed;
}
.dark .select .select__control--is-disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.select .select__control--is-disabled .select__placeholder {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.select .select__control--is-disabled .select__multi-value {
  opacity: 0.6;
}
.select .select__placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.select .select__multi-value {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.dark .select .select__multi-value {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.select .select__multi-value .select__multi-value__label {
  color: inherit;
}
.select .select__multi-value__remove:hover {
  --tw-text-opacity: 1;
  background-color: initial;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.dark .select .select__multi-value__remove:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.select.select-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.select.select-lg .select-dropdown-indicator {
  font-size: 1.5rem;
  line-height: 2rem;
}
.select .select__single-value {
  color: inherit;
}
.select .select__single-value--is-disabled {
  opacity: 0.7;
}
.dark .select .select__input input {
  --tw-text-opacity: 1 !important;
  color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
}
.select .select__control {
  height: auto;
}
.dark .select .select__control {
  background-color: initial;
}
.select .select__control.hide-single-value .select__single-value {
  display: none !important;
}
.select__menu {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.dark .select__menu,
.select__menu {
  --tw-bg-opacity: 1;
}
.dark .select__menu {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.select-option {
  align-items: center;
  cursor: default;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
}
.select-option:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.dark .select-option:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.select-option.selected {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.dark .select-option.selected {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.select-option.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.select-option.disabled:hover {
  background-color: initial;
}
.dark .select-option.disabled {
  opacity: 0.3;
}
.select-dropdown-indicator {
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
}
.select-dropdown-indicator:hover {
  opacity: 0.7;
}
.select-clear-indicator {
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.5rem;
}
.select-clear-indicator:hover {
  opacity: 0.7;
}

/* ================================================================================================================ */
/* 18. Time Line */
/* ================================================================================================================ */

.timeline {
  @apply flex-col items-start;
}

.timeline-item {
  @apply flex min-h-[70px];
}

.timeline-item-last {
  @apply min-h-max;
}

.timeline-item-media {
  @apply flex flex-col items-center;
}

/* .timeline-item-media-content {
    @apply my-1;
} */

.timeline-item-media-default {
  @apply rounded-full bg-gray-200 dark:bg-gray-600 h-[11px] w-[11px];
}

.timeline-item-wrapper {
  @apply flex flex-auto;
}

.timeline-item-content {
  @apply ml-4 rtl:mr-4 w-full mt-[-6px];
}

.timeline-item-content-last {
  @apply pb-0;
}

.timeline-connect {
  @apply h-full w-[1px] bg-gray-200 dark:bg-gray-600 flex-grow;
}
