.picker-table,
.picker-view {
  width: 100%;
}
.picker-view {
  display: flex;
  gap: 1.5rem;
  min-width: 260px;
}
.day-picker,
.month-picker,
.year-picker {
  width: 100%;
}
.picker-header-label {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  -webkit-user-select: none;
  user-select: none;
}

/* button,
select {
  text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
} */
.picker-table {
  border-collapse: collapse;
}
.picker-table,
.picker-view {
  width: 100%;
}
.week-day-cell {
  height: 1.75rem;
}
td,
th {
  word-wrap: break-word;
}
.date-picker-week-cell {
  font-weight: 600;
}
.date-picker-cell {
  height: 2.25rem;
  padding: 0;
  text-align: center;
  width: 2.25rem;
}
.date-picker-cell-hoverable:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.date-picker-other-month {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.date-picker-cell-content {
  font-weight: 400;
  height: 100%;
  width: 100%;
}
.month-picker-cell,
.year-picker-cell {
  border-radius: 0.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
}
.month-table,
.year-table {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.date-picker-cell-disabled {
  --tw-bg-opacity: 1;
  cursor: not-allowed;
  opacity: 0.3;
}
*,
::backdrop,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}
